/* eslint react/jsx-one-expression-per-line: 0 */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {injectIntl, FormattedMessage} from 'react-intl';
import { StaticQuery, graphql, Link } from 'gatsby'
import {When} from 'react-if';

import fixInternalLink from '../../../helpers/fixInternalLinks';
import sendEvent from '../../../helpers/sendAnalytics'

import BgImage from '../../common/BgImage';
import FooterSubscribe from '../../common/FooterSubscribe';

import fbicon from '../../../assets/images/fb-color.svg'
import instaicon from '../../../assets/images/insta-color.svg'
import Image from "../../common/Image";


const FooterContainer = styled.footer`
  position: relative;
  margin-top: 50px;
  overflow: hidden;


  @media (max-width: 1280px) {
    padding-bottom: 60px;
  }
`

const FooterBG = styled.div`
  ${tw`absolute pin flex`}
`

const FooterBGLeft = styled.div`
  ${tw`flex-1`}
`

const FooterBGRight = styled.div`
  ${tw`flex-1 bg-light-blue`}

  @media (max-width: 1280px) {
    width: 100%;
    display: none;
  }
`

const FooterWrapper = styled.div`
  ${tw`relative flex mx-auto flex-wrap`}
  z-index: 1;
  max-width: 1260px;
  padding-top: 100px;
  padding-bottom: 80px;

  @media (max-width: 1280px) {
    ${tw`flex-col-reverse mb-0 pb-0 pt-0`}

    &.hidesignup {
      & > ul {
        margin-top: 0 !important;
      }
    }
  }
`

const FooterSocialIcons = styled.div`
  margin-bottom: 1rem;
  ${tw`flex`}

  @media(max-width: 1280px) {
    order:1;
  margin-left: 20px;
  }
`

const FooterLeft = styled.div`
  width: 50%;

  &.hidesignupleft ${FooterSocialIcons} {
    display: none;
  }

  @media (max-width: 1280px) {
    width: 100%;
    ${tw`px-5 py-10`}

    ${FooterSocialIcons} {
      ${tw`flex`}
    }

    .fixed-legal {
      margin: 20px 0 !important;
      padding: 0 !important;
    }
  }
  @media (max-width: 830px) {
    order: 2
  }
`

const FooterRight = styled.div`
  position: relative;
  width: 50%;
  padding-left: 220px;

  input[type=text],
  input[type=email] {
    ${tw`font-raleway mb-0 text-base leading-tight border-solid border border-grey w-full focus:shadow-outline hover:shadow-outline focus:outline-none hover:outline-none appearance-none rounded-none`}
    height: 50px;
    padding: 15px 20px;
    appearance-none
    focus:outline-none
    focus:shadow-outline
  }

  @media (max-width: 1280px) {
    width: 100%;
    ${tw`bg-light-blue px-5 py-10`}
  }
  @media (max-width: 830px) {
    order: 3
  }
`

const FooterHeader = styled.h2`
  ${tw`font-montserrat flex m-0 mb-6 items-center text-2xl leading-tight font-normal`}

  &:before {
    content: '';
    width: 40px;
    height: 3px;
    margin-right: 20px;
    background: #4D8DCD;
  }
`

const FooterHeaderRight = styled.h2`
  ${tw`font-montserrat flex m-0 mb-6 items-center text-4xl leading-tight font-normal`}
`

const FooterText = styled.p`
  ${tw`font-raleway text-lg leading-tight mb-4`}
  max-width: 500px;

  a {
    color: #4d8dcd;
  }
`

const FooterSocialIcon = styled.a`
  margin-right: 20px;
`

const FooterMenu = styled.ul`
  ${tw`flex mb-0 pl-0 list-reset relative z-10`}
  margin-top:${props => props.nomargin ? '0':'400px'};

  @media (max-width: 1280px) {
    margin-top: 50px;
    ${tw`flex-col`}
    margin-left:${props => props.nomargin ? '20px':'0px'}
  }
`

const FooterMenuItem = styled.li`
  margin-right: 20px;

  a {
    ${tw`font-raleway font-blog-text text-base leading-tight no-underline text-blog-text focus:underline hover:underline`}
  }

  @media (max-width: 1280px) {
    a {
      ${tw`py-2 block`}
    }
  }
`

const FooterSubscribeImage = styled.div`
  position: absolute;
  left: -300px;
  top: -50px;
  width: 500px;
  height: 673px;

  @media (max-width: 1280px) {
    display: none;
  }
`

const FooterQRCode = styled(Image)`
  width: 176px;
  @media (max-width: 850px) {
    width: 150px;
  }
`

const ExtraLegal = styled.section`
  background: #F7F9FC;

  ${tw`font-raleway text-sm mt-0 leading-normal text-gray-600 text-center py-12 pb-24`}

  p, small {
    display: block;
    max-width: 750px !important;
    margin: 0 auto !important;
  }


  @media screen and (max-width: 54em) {
    p, small {
      padding: 0 5%;
    }
  }
`

const Footer = (props) => {
  const { image, intl, hideSignUp, bath, extraRules, drawerRules, paperPack, cn, petsPageFooterText
   } = props;
  let footerimage = `defaultimage${intl.locale}`;
  if (image) {

    footerimage = `${image}${intl.locale}`;
  }

  if (intl.locale !== 'en' && intl.locale !== 'fr') {
    footerimage = `defaultimageen`;
  }
  const menuItems = [
    {
      to: "c1ba4863-8f29-4802-bd3d-0f74c7244288",
      name: intl.formatMessage({ id:"footer.faqs" }),
    },
    {
      to: "253c3d63-d4b9-4068-a474-9ea6faec2c9d",
      name: intl.formatMessage({ id:"footer.privacy_policy" }),
    },
    {
      to: "6792250d-8ba2-4571-a850-07c4acfcaf47",
      name: intl.formatMessage({ id:"footer.terms_of_service" }),
    },
    {
      to: "5ebdf4cd-c7e3-4905-a0f5-9b8a5ef6e17c",
      name: intl.formatMessage({ id:"footer.past_winners" }),
    }
  ]

  if(hideSignUp){
    return (
      <FooterContainer>
        <FooterWrapper className="hidesignup">
          <FooterLeft className="hidesignupleft">
            <FooterHeader>
              <FormattedMessage id='footer.contact_us' />
            </FooterHeader>
            <FooterText>
              <FormattedMessage id='footer.contact_txt' />
            </FooterText>
            <FooterText>
              <FormattedMessage id='footer.send_email'>
                {(txt) => {
                  let updatedtxt = txt
                  if (intl.locale === 'en') {
                    updatedtxt = txt.replace("'>","' onclick=\"sendEvent({ga: {category: 'Contact_Us', action: 'Click', label:'Footer_Email_Click'}}:null\">")
                  } else {
                    updatedtxt = txt.replace("'>","' onclick=\"sendEvent({ga: {category: 'Contact_Us', action: 'Click', label:'Footer_Email_Click'}}:null\">")
                  }

                  return (<span dangerouslySetInnerHTML={{__html:updatedtxt}} />)
                }}
              </FormattedMessage>
            </FooterText>

            <FooterSocialIcons nomargin>
              <FooterSocialIcon href="https://www.facebook.com/RoyaleKittensChatons" target="_blank" rel="noopener noreferrer" onClick={() => sendEvent({ ga: { category: 'Social_Click_Through', action: 'Click', Label: (intl.locale === 'en') ? 'Facebook_Click' : 'Facebook_Click_FR'}})}>
                <img src={fbicon} alt="fb" />
              </FooterSocialIcon>
              <FooterSocialIcon href="https://www.instagram.com/theroyalekittens/" target="_blank" rel="noopener noreferrer" onClick={() => sendEvent({ ga: { category: 'Social_Click_Through', action: 'Click', Label: (intl.locale === 'en') ? 'Instagram_Click' : 'Instagram_Click_FR'}})}>
                <img src={instaicon} alt="insta" />
              </FooterSocialIcon>
            </FooterSocialIcons>


          </FooterLeft>

          <FooterSocialIcons nomargin>
            <FooterSocialIcon href="https://www.facebook.com/RoyaleKittensChatons" target="_blank" rel="noopener noreferrer" onClick={() => sendEvent({ ga: { category: 'Social_Click_Through', action: 'Click', Label: (intl.locale === 'en') ? 'Facebook_Click' : 'Facebook_Click_FR'}})}>
              <img src={fbicon} alt="fb" />
            </FooterSocialIcon>
            <FooterSocialIcon href="https://www.instagram.com/theroyalekittens/" target="_blank" rel="noopener noreferrer" onClick={() => sendEvent({ ga: { category: 'Social_Click_Through', action: 'Click', Label: (intl.locale === 'en') ? 'Instagram_Click' : 'Instagram_Click_FR'}})}>
              <img src={instaicon} alt="insta" />
            </FooterSocialIcon>
          </FooterSocialIcons>

          {intl.locale !== 'en' && intl.locale !== 'fr' ? <FooterQRCode url="https://royaleapi.irvingconsumerproducts.com/globalassets/promotion/wechat/royale-wechat-qrcode.png" alt="Royale" sizes="(min-width: 64em) 176px, 150px" /> : null}

          <FooterMenu nomargin>
            {menuItems.map(item => (
              <FooterMenuItem>
                <Link to={fixInternalLink(item.to, intl.locale)} key={item.to}>{item.name}</Link>
              </FooterMenuItem>
            ))}
          </FooterMenu>
        </FooterWrapper>

        <ExtraLegal>
          <When condition={extraRules !== null}>
            <small className="pets-legal" dangerouslySetInnerHTML={{__html:extraRules}} />
            <br />
          </When>
          
          <When condition={petsPageFooterText !== false && petsPageFooterText !== null }>
            <small className="pets-legal" dangerouslySetInnerHTML={{__html:petsPageFooterText}} />
            <br />
          </When>

          <FormattedMessage id="footer.legal">
            {(txt) => (
              <small className="pets-legal" dangerouslySetInnerHTML={{__html: txt}} />
            )}
          </FormattedMessage>
        </ExtraLegal>
      </FooterContainer>
    )
  }

  return (
    <>
      <FooterContainer id="footer">
        <FooterBG>
          <FooterBGLeft />
          <FooterBGRight />
        </FooterBG>
        <FooterWrapper>
          <FooterLeft>
            <FooterHeader>
              <FormattedMessage id='footer.contact_us' />
            </FooterHeader>
            <FooterText>
              <FormattedMessage id='footer.contact_txt' />
            </FooterText>
            <FooterText>
              <FormattedMessage id='footer.send_email'>
                {(txt) => {
                  let updatedtxt = txt
                  if (intl.locale === 'en') {
                    updatedtxt = txt.replace("'>","' onclick=\"sendEvent({ga: {category: 'Contact_Us', action: 'Click', label:'Footer_Email_Click'}}:null\">")
                  } else {
                    updatedtxt = txt.replace("'>","' onclick=\"sendEvent({ga: {category: 'Contact_Us', action: 'Click', label:'Footer_Email_Click'}}:null\">")
                  }

                  return (<span dangerouslySetInnerHTML={{__html:updatedtxt}} />)
                }}
              </FormattedMessage>
            </FooterText>
            <FooterSocialIcons>
              <FooterSocialIcon href="https://www.facebook.com/RoyaleKittensChatons" target="_blank" rel="noopener noreferrer" onClick={() => sendEvent({ ga: { category: 'Social_Click_Through', action: 'Click', Label: (intl.locale === 'en') ? 'Facebook_Click' : 'Facebook_Click_FR'}})}>
                <img src={fbicon} alt="fb" />
              </FooterSocialIcon>
              <FooterSocialIcon href="https://www.instagram.com/theroyalekittens/" target="_blank" rel="noopener noreferrer" onClick={() => sendEvent({ ga: { category: 'Social_Click_Through', action: 'Click', Label: (intl.locale === 'en') ? 'Instagram_Click' : 'Instagram_Click_FR'}})}>
                <img src={instaicon} alt="insta" />
              </FooterSocialIcon>
            </FooterSocialIcons>

          <FooterMenu>
            {menuItems.map(item => (
              <FooterMenuItem>
                <Link to={fixInternalLink(item.to, intl.locale)} key={item.to}>{item.name}</Link>
              </FooterMenuItem>
            ))}
          </FooterMenu>

        </FooterLeft>
        <FooterRight>
          <StaticQuery
            query={graphql`
              query {
                defaultimageen: file(base:{eq:"defaultimageen.png"}) {
                  childImageSharp {
                    fixed(width: 500, quality: 90) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                  }
                }
                tigertowelen: file(base:{eq:"tigertowelen.png"}) {
                  childImageSharp {
                    fixed(width: 500, quality: 90) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                  }
                }
                bathroomtissueen: file(base:{eq:"bathroomtissueen.png"}) {
                  childImageSharp {
                    fixed(width: 500, quality: 90) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                  }
                }
                defaultimagefr: file(base:{eq:"defaultimagefr.png"}) {
                  childImageSharp {
                    fixed(width: 500, quality: 90) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                  }
                }
                tigertowelfr: file(base:{eq:"tigertowelfr.png"}) {
                  childImageSharp {
                    fixed(width: 500, quality: 90) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                  }
                }
                bathroomtissuefr: file(base:{eq:"bathroomtissuefr.png"}) {
                  childImageSharp {
                    fixed(width: 500, quality: 90) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                  }
                }
              }
              `}
              render={data => (
                <FooterSubscribeImage>
                  <BgImage fixed={data[footerimage].childImageSharp.fixed} />
                </FooterSubscribeImage>
              )}
            />
            <FooterHeaderRight>
              <FormattedMessage id='footer.footer_header' />
            </FooterHeaderRight>
            <FooterText>
              <FormattedMessage id='footer.footer_top' />
            </FooterText>
            <FooterSubscribe />
          </FooterRight>
        </FooterWrapper>
      </FooterContainer>

      <ExtraLegal>
        <When condition={drawerRules}>
            <FormattedMessage id='drawer.rules'>
              {(txt) => (
                <small class="pets-legal" dangerouslySetInnerHTML={{__html: txt}} />
              )}
            </FormattedMessage>
            <br />
        </When>
        <When condition={extraRules !== null}>
          <small className="pets-legal" dangerouslySetInnerHTML={{__html:extraRules}} />
          <br />
        </When>
        <When condition={paperPack}>
            <FormattedMessage id='footer.paperpack'>
              {(txt) => (
                <small class="pets-legal" dangerouslySetInnerHTML={{__html: txt}} />
              )}
            </FormattedMessage>
            <br />
        </When>

        { cn ?
          <FormattedMessage id="footer.legalcn">
            {(txt) => (
              <small className="pets-legal" dangerouslySetInnerHTML={{__html: txt}} />
            )}
          </FormattedMessage>
          :
          <FormattedMessage id="footer.legal">
            {(txt) => (
              <small className="pets-legal" dangerouslySetInnerHTML={{__html: txt}} />
            )}
          </FormattedMessage>
        }




      </ExtraLegal>
    </>
  )
};

Footer.propTypes = {
  image: PropTypes.string,
  extraRules: PropTypes.string,
  petsPageFooterText : PropTypes.string,
}

Footer.defaultProps = {
  image: 'defaultimage',
  extraRules: null
}

export default injectIntl(Footer)